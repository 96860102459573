<template>
  <b-container
    class="mb-5"
    fluid
  >
    <div class="d-flex justify-content-end mb-3 pb-0">
      <b-breadcrumb
        v-if="$route.meta.breadcrumb"
        :items="$route.meta.breadcrumb || []"
      />
    </div>

    <b-card>
      <b-row class="mb-2">
        <b-col cols="12">
          <div class="form-group">
            <label>
              <strong>Search</strong>
            </label>
            <b-input
              v-model="tablePending.filter.search"
              type="text"
              placeholder="search here"
              debounce="1000"
            />
          </div>
        </b-col>
        <b-col cols="12">
          <b-button
            type="button"
            variant="success"
            @click="onCreate"
          >
            Create Reservation
          </b-button>
        </b-col>
      </b-row>

      <b-table
        ref="table"
        hover
        responsive
        class="mt-2"
        :per-page="tablePending.perPage"
        :current-page="tablePending.currentPage"
        :items="tableProvider"
        :fields="tablePending.fields"
        :sort-by.sync="tablePending.sortBy"
        :sort-desc.sync="tablePending.sortDesc"
        :sort-direction="tablePending.sortDirection"
        :filter="tablePending.filter"
        :filter-included-fields="tablePending.filterOn"
        show-empty
      >
        <template #cell(index)="data">
          {{ tablePending.currentPage * tablePending.perPage - tablePending.perPage + (data.index + 1) }}
        </template>

        <template #cell(departure)="data">
          <div class="text-nowrap">
            {{ dateTimeFormatterLong(`${data.item.departure_date} ${data.item.departure_time}`) }}
          </div>
        </template>

        <template #cell(arrival)="data">
          <div class="text-nowrap">
            {{ dateTimeFormatterLong(`${data.item.arrival_date} ${data.item.arrival_time}`) }}
          </div>
        </template>

        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>

        <template #cell(actions)="row">
          <div class="text-nowrap">
            <b-button
              size="sm"
              class="mr-1"
              @click="onView(row.item)"
            >
              View
            </b-button>

            <b-button
              size="sm"
              variant="outline-danger"
              @click="onCancel(row.item)"
            >
              Cancel
            </b-button>
          </div>
        </template>

      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tablePending.perPage"
              :options="tablePending.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tablePending.currentPage"
            :total-rows="tablePending.totalRows"
            :per-page="tablePending.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <!-- modal new reservation -->
    <b-modal
      id="modal-reservation-form"
      size="xl"
      scrollable
      hide-header
      no-close-on-esc
      no-enforce-focus
      no-close-on-backdrop
      aria-hidden="false"
      modal-class="full-height"
      @ok="onValidate"
    >
      <b-container
        class="h-100 py-1 px-1"
        fluid
      >

        <ValidationObserver ref="form">
          <form
            role="form"
            novalidate
            @submit.prevent
          >
            <b-tabs
              active-nav-item-class="font-weight-bold text-uppercase text-success"
              content-class="scrollable"
            >
              <b-tab
                active
              >
                <template #title>
                  <div class="text-md-nowrap">
                    <i
                      :class="['ri-file-text-line h4', {
                        'text-danger fat fa-shake': vTabReservation()
                      }]"
                    />
                    <b
                      :class="['is-tab-active ml-1', {
                        'text-danger': vTabReservation()
                      }]"
                    >
                      Details
                    </b>
                  </div>
                </template>
                <b-row class="p-2">

                  <b-col
                    cols="12"
                    md="6"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      name="company"
                      vid="company"
                      rules="required"
                    >
                      <b-form-group>
                        <label for="company">
                          <strong>Company</strong>
                        </label>
                        <b-form-select
                          id="company"
                          v-model="reservation.company"
                          :options="list.companies"
                          type="text"
                          :state="errors.length > 0 ? false : null"
                          :disabled="state.busy || state.file.viewing"
                          @change="onCompanyChange"
                        >
                          <template #first>
                            <b-form-select-option
                              :value="null"
                              disabled
                            >
                              -- select here --
                            </b-form-select-option>
                          </template>
                        </b-form-select>
                        <div
                          v-if="errors.length > 0"
                          class="invalid-feedback"
                        >
                          <span>{{ errors[0] }}</span>
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      name="organization"
                      vid="organization"
                      rules="required"
                    >
                      <b-form-group>
                        <label for="organization">
                          <strong>Organization</strong>
                        </label>
                        <b-form-select
                          id="organization"
                          v-model="reservation.organization"
                          :options="filteredOrganizations"
                          type="text"
                          :state="errors.length > 0 ? false : null"
                          :disabled="state.busy || state.file.viewing"
                        >
                          <template #first>
                            <b-form-select-option
                              :value="null"
                              disabled
                            >
                              -- select here --
                            </b-form-select-option>
                          </template>
                        </b-form-select>
                        <div
                          v-if="errors.length > 0"
                          class="invalid-feedback"
                        >
                          <span>{{ errors[0] }}</span>
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>

                  <b-col
                    cols="12"
                    md="4"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      name="reservation type"
                      vid="reservation_type"
                      rules="required"
                    >
                      <b-form-group>
                        <label for="reservation_type">
                          <strong>Reservation Type</strong>
                        </label>
                        <b-form-select
                          id="reservation_type"
                          v-model="reservation.reservation_type"
                          :options="list.reservationTypes"
                          type="text"
                          :state="errors.length > 0 ? false : null"
                          :disabled="state.busy || state.file.viewing"
                          @change="onReservationTypeChange"
                        >
                          <template #first>
                            <b-form-select-option
                              :value="null"
                              disabled
                            >
                              -- select here --
                            </b-form-select-option>
                          </template>
                        </b-form-select>
                        <div
                          v-if="errors.length > 0"
                          class="invalid-feedback"
                        >
                          <span>{{ errors[0] }}</span>
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>

                  <b-col
                    cols="12"
                    md="2"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      name="passenger count"
                      vid="passenger_count"
                      rules="required_if:reservation_type,Service Vehicle,Service Driver And Vehicle|numeric|max_value:500"
                    >
                      <b-form-group>
                        <label for="passenger_count">
                          <strong>Passenger Count</strong>
                        </label>
                        <b-input
                          id="passenger_count"
                          v-model="reservation.passenger_count"
                          type="number"
                          :state="errors.length > 0 ? false : null"
                          :disabled="state.busy || state.file.viewing || reservation.reservation_type === 'Service Driver'"
                          max="500"
                        />
                        <div
                          v-if="errors.length > 0"
                          class="invalid-feedback"
                        >
                          <span>{{ errors[0] }}</span>
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>

                  <b-col
                    cols="12"
                    md="6"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      name="vehicle type"
                      vid="vehicle_type"
                      rules="required"
                    >
                      <b-form-group>
                        <label for="vehicle_type">
                          <strong>Vehicle Type</strong>
                        </label>
                        <b-form-select
                          id="vehicle_type"
                          v-model="reservation.vehicle_type"
                          :options="list.vehicleTypes"
                          type="text"
                          :state="errors.length > 0 ? false : null"
                          :disabled="state.busy || state.file.viewing"
                        >
                          <template #first>
                            <b-form-select-option
                              :value="null"
                              disabled
                            >
                              -- select here --
                            </b-form-select-option>
                          </template>
                        </b-form-select>
                        <div
                          v-if="errors.length > 0"
                          class="invalid-feedback"
                        >
                          <span>{{ errors[0] }}</span>
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      name="departure date"
                      vid="departure_date"
                      rules="required"
                    >
                      <b-form-group>
                        <label for="departure_date">
                          <strong>Departure Date</strong>
                        </label>
                        <b-input
                          id="departure_date"
                          v-model="reservation.departure_date"
                          type="date"
                          :state="errors.length > 0 ? false : null"
                          :disabled="state.busy || state.file.viewing"
                        />
                        <div
                          v-if="errors.length > 0"
                          class="invalid-feedback"
                        >
                          <span>{{ errors[0] }}</span>
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>

                  <b-col
                    cols="12"
                    md="2"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      name="departure time"
                      vid="departure_time"
                      rules="required"
                    >
                      <b-form-group>
                        <label for="departure_time">
                          <strong>Departure Time</strong>
                        </label>
                        <b-input
                          id="departure_time"
                          v-model="reservation.departure_time"
                          type="time"
                          :state="errors.length > 0 ? false : null"
                          :disabled="state.busy || state.file.viewing"
                        />
                        <div
                          v-if="errors.length > 0"
                          class="invalid-feedback"
                        >
                          <span>{{ errors[0] }}</span>
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>

                  <b-col
                    cols="12"
                    md="4"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      name="arrival date"
                      vid="arrival_date"
                      rules="required"
                    >
                      <b-form-group>
                        <label for="arrival_date">
                          <strong>Arrival Date</strong>
                        </label>
                        <b-input
                          id="arrival_date"
                          v-model="reservation.arrival_date"
                          type="date"
                          :state="errors.length > 0 ? false : null"
                          :disabled="state.busy || state.file.viewing"
                        />
                        <div
                          v-if="errors.length > 0"
                          class="invalid-feedback"
                        >
                          <span>{{ errors[0] }}</span>
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>

                  <b-col
                    cols="12"
                    md="2"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      name="arrival time"
                      vid="arrival_time"
                      rules="required"
                    >
                      <b-form-group>
                        <label for="arrival_time">
                          <strong>Arrival Time</strong>
                        </label>
                        <b-input
                          id="arrival_time"
                          v-model="reservation.arrival_time"
                          type="time"
                          :state="errors.length > 0 ? false : null"
                          :disabled="state.busy || state.file.viewing"
                        />
                        <div
                          v-if="errors.length > 0"
                          class="invalid-feedback"
                        >
                          <span>{{ errors[0] }}</span>
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>

                  <b-col
                    cols="12"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      name="purpose"
                      vid="purpose"
                      rules="required"
                    >
                      <b-form-group>
                        <label for="purpose">
                          <strong>Purpose</strong>
                        </label>
                        <b-textarea
                          id="purpose"
                          v-model="reservation.purpose"
                          rows="6"
                          max-rows="6"
                          autocomplete="off"
                          placeholder="please state your travel arrangements"
                          :state="errors.length > 0 ? false : null"
                          :disabled="state.busy || state.file.viewing"
                        />
                        <div
                          v-if="errors.length > 0"
                          class="invalid-feedback"
                        >
                          <span>{{ errors[0] }}</span>
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>

                </b-row>
              </b-tab>

              <b-tab>
                <template #title>
                  <div class="text-md-nowrap">
                    <i class="ri-attachment-line h4" />
                    <b class="is-tab-active ml-1">
                      Attachments
                    </b>
                  </div>
                </template>

                <div class="p-2">
                  <b-row v-if="!state.file.viewing">
                    <b-col cols="12">
                      <b-form-file
                        v-model="list.files"
                        accept=".jpg, .jpeg, .png"
                        multiple
                        class="mb-2"
                        :disabled="state.busy || (parseInt(state.allowSubmit) === 1 && !state.file.having)"
                      />
                    </b-col>

                    <b-col cols="12">
                      <b-button
                        variant="success"
                        :disabled="state.busy || state.file.uploading || list.files.length < 1 || (parseInt(state.allowSubmit) === 1 && !state.file.having)"
                        class="mr-1"
                        size="sm"
                        @click="onUploadAttachment"
                      >
                        {{ state.file.uploading ? 'Uploading Files...' : 'Upload Files' }}
                      </b-button>
                      <b-button
                        variant="danger"
                        size="sm"
                        :disabled="reservation.file_list.length < 1"
                        @click="onRemoveAllFile"
                      >
                        {{ state.file.removing ? 'Removing Files...' : 'Remove Files' }}
                      </b-button>
                    </b-col>
                  </b-row>

                  <b-table
                    v-if="reservation.file_list.length > 0 || state.file.viewing"
                    class="mt-1"
                    :fields="tableAttachments.fields"
                    :items="reservation.file_list"
                    responsive
                    striped
                    hover
                  >
                    <template #cell(actions)="row">
                      <div class="text-nowrap">
                        <b-button
                          size="sm"
                          class="mr-1"
                          :disabled="state.file.previewing"
                          @click="onPreviewAttachment(row.item)"
                        >
                          Preview
                        </b-button>
                      </div>
                    </template>
                  </b-table>
                </div>
              </b-tab>
            </b-tabs>

          </form>
        </ValidationObserver>

      </b-container>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="primary"
          :disabled="state.busy"
          @click="ok()"
        >
          Submit Reservation
        </b-button>
        <b-button
          variant="outline-dark"
          :disabled="state.busy"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

    <!-- Modal View Form -->
    <b-modal
      id="modal-view-form"
      size="xl"
      scrollable
      hide-header
      no-close-on-esc
      no-enforce-focus
      no-close-on-backdrop
      aria-hidden="false"
      modal-class="full-height"
      title="View Detail"
    >
      <b-card no-body>
        <form
          role="form"
          novalidate
          @submit.prevent
        >
          <b-tabs
            active-nav-item-class="font-weight-bold text-uppercase text-success"
            content-class="scrollable"
          >
            <b-tab
              active
            >
              <template #title>
                <div class="text-md-nowrap">
                  <i class="ri-file-text-line h4" />
                  <b class="is-tab-active ml-1">
                    Details
                  </b>
                </div>
              </template>
              <b-row class="p-2">

                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group>
                    <label for="reserved_at">
                      <strong>Reserved At</strong>
                    </label>
                    <b-input
                      id="reserved_at"
                      v-model="reservation.created_at"
                      type="text"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group>
                    <label for="status">
                      <strong>Status</strong>
                    </label>
                    <b-input
                      id="status"
                      v-model="reservation.status"
                      type="text"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group>
                    <label for="company">
                      <strong>Company</strong>
                    </label>
                    <b-input
                      id="company"
                      v-model="reservation.company_name"
                      type="text"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group>
                    <label for="organization">
                      <strong>Organization</strong>
                    </label>
                    <b-input
                      id="organization"
                      v-model="reservation.organization_name"
                      type="text"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group>
                    <label for="reservation_type">
                      <strong>Reservation Type</strong>
                    </label>
                    <b-input
                      id="reservation_type"
                      v-model="reservation.reservation_type"
                      type="text"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="2"
                >
                  <b-form-group>
                    <label for="passenger_count">
                      <strong>Passenger Count</strong>
                    </label>
                    <b-input
                      id="passenger_count"
                      v-model="reservation.passenger_count"
                      type="text"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group>
                    <label for="vehicle_type">
                      <strong>Vehicle Type</strong>
                    </label>
                    <b-input
                      id="vehicle_type"
                      v-model="reservation.vehicle_type_name"
                      type="text"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group>
                    <label for="departure_date">
                      <strong>Departure Date</strong>
                    </label>
                    <b-input
                      id="departure_date"
                      v-model="reservation.departure_date"
                      type="text"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="2"
                >
                  <b-form-group>
                    <label for="departure_time">
                      <strong>Departure Time</strong>
                    </label>
                    <b-input
                      id="departure_time"
                      v-model="reservation.departure_time"
                      type="text"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="4"
                >
                  <b-form-group>
                    <label for="arrival_date">
                      <strong>Arrival Date</strong>
                    </label>
                    <b-input
                      id="arrival_date"
                      v-model="reservation.arrival_date"
                      type="text"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="2"
                >
                  <b-form-group>
                    <label for="arrival_time">
                      <strong>Arrival Time</strong>
                    </label>
                    <b-input
                      id="arrival_time"
                      v-model="reservation.arrival_time"
                      type="text"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                >
                  <b-form-group>
                    <label for="purpose">
                      <strong>Purpose</strong>
                    </label>
                    <b-textarea
                      id="purpose"
                      v-model="reservation.purpose"
                      type="text"
                      rows="6"
                      max-rows="6"
                      disabled
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-tab>

            <b-tab
              v-if="reservation.attachments.length > 0"
            >
              <template #title>
                <div class="text-md-nowrap">
                  <i class="ri-attachment-line h4" />
                  <b class="is-tab-active ml-1">
                    Attachments
                  </b>
                </div>
              </template>
              <div class="p-2">
                <b-table
                  class="mt-1"
                  :fields="tableAttachments.fields"
                  :items="reservation.attachments"
                  responsive
                  striped
                  hover
                >
                  <template #cell(actions)="row">
                    <div class="text-nowrap">
                      <b-button
                        size="sm"
                        class="mr-1"
                        @click="onPreviewAttachment(row.item)"
                      >
                        Preview
                      </b-button>
                    </div>
                  </template>
                </b-table>
              </div>
            </b-tab>
          </b-tabs>
        </form>
      </b-card>

      <template #modal-footer="{ cancel }">
        <b-button
          variant="outline-dark"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>

    </b-modal>

    <!-- modal cancel form -->
    <b-modal
      id="modal-cancel-form"
      scrollable
      no-close-on-backdrop
      no-enforce-focus
      aria-hidden="false"
      title="Cancel Reservation"
      @ok="onCancelReservation"
    >
      <ValidationObserver ref="formCancelReservation">
        <form
          role="form"
          novalidate
          @submit.prevent
        >
          <ValidationProvider
            #default="{ errors }"
            name="cancellation reason"
            vid="cancellation_reason"
            rules="required|max:200"
          >
            <div class="form-group">
              <label for="cancellation_reason">
                <strong>Reason</strong>
              </label>
              <b-form-textarea
                id="cancellation_reason"
                v-model="cancellation.cancellation_reason"
                rows="6"
                max-rows="6"
                type="text"
                :class="[errors.length > 0 ? ' is-invalid' : '']"
                placeholder="enter cancellation reason"
                autocomplete="off"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </div>
          </ValidationProvider>

        </form>
      </ValidationObserver>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="ok()"
        >
          Mark as Canceled
        </b-button>
        <b-button
          variant="outline-dark"
          :disabled="state.busy"
          @click="cancel()"
        >
          Close
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-preview-image"
      size="lg"
      scrollable
      no-close-on-backdrop
      no-enforce-focus
      aria-hidden="false"
      :title="`Preview Attachment [${file.name}]`"
    >
      <div class="d-flex justify-content-center">
        <b-img
          fluid
          :src="file.preview"
        />
      </div>

      <template #modal-footer="{ cancel }">
        <b-button
          variant="outline-dark"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

  </b-container>
</template>

<script>
import { core } from '@/config/pluginInit'
import { AxiosError, SUserReservation, SUserAttachment, SSharedList } from '@/services'
import formatter from '@/mixins/formatter'
import url from '@/mixins/url'
import _ from 'lodash'

export default {
  name: 'VUserPending',

  middleware: ['auth', 'user'],

  metaInfo () {
    return {
      title: 'Pending Reservations'
    }
  },

  mixins: [formatter, url],

  data () {
    return {
      state: {
        busy: false,
        viewing: false,
        file: {
          having: false,
          removing: false,
          uploading: false,
          previewing: false
        },
        allowSubmit: 0
      },
      file: {
        name: '',
        preview: null
      },
      list: {
        files: [],
        companies: [],
        organizations: [],
        vehicleTypes: [],
        reservationTypes: [
          { text: 'Service Driver', value: 'Service Driver' },
          { text: 'Service Vehicle', value: 'Service Vehicle' },
          { text: 'Service Driver And Vehicle', value: 'Service Driver And Vehicle' }
        ]
      },
      reservation: {
        id: 0,
        company: null,
        organization: null,
        reservation_type: 'Service Driver',
        departure_date: this.dateFormatter(Date.now()),
        departure_time: '',
        arrival_date: this.dateFormatter(Date.now()),
        arrival_time: '',
        vehicle_type: null,
        passenger_count: 0,
        purpose: '',
        file_list: [],
        file_path: null,
        attachments: []
      },
      cancellation: {
        id: 0,
        cancellation_reason: ''
      },
      tablePending: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: {
          search: ''
        },
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'created_at', formatter: this.dateTimeFormatter, label: 'reserve at' },
          { key: 'reservation_type' },
          { key: 'vehicle_type_name', label: 'Vehicle Type' },
          'departure',
          'arrival',
          { key: 'status' }
        ]
      },
      tableAttachments: {
        fields: [
          'actions',
          { key: 'file_original_name', label: 'filename' }
        ]
      }
    }
  },

  computed: {
    modalTitle () {
      return this.state.viewing ? 'View Reservation' : 'New Reservation'
    },

    filteredOrganizations () {
      return _.filter(this.list.organizations, {
        company_id: this.reservation.company
      }).map(
        organization => ({
          text: organization.name,
          value: organization.id
        })
      )
    }
  },

  watch: {
    'reservation.file_list' (value) {
      if (value.length > 0) {
        this.state.file.having = true
      } else {
        this.state.file.having = false
      }
    },

    'state.file.having' (value) {
      if (value) {
        this.state.allowSubmit = 1
      } else {
        this.state.allowSubmit = 0
      }
    }
  },

  mounted () {
    core.index()
    this.getOptions()
  },

  methods: {

    vTabReservation () {
      return this.$refs.form && Object.keys(this.$refs.form.errors).find(
        key => this.$refs.form.errors[key]?.length > 0
      )
    },

    async tableProvider ({ currentPage, perPage, sortBy, sortDesc, filter }) {
      const filters = this.objectToUrl({
        page: currentPage,
        per_page: perPage,
        sort: sortBy,
        sort_desc: sortDesc,
        filter_text: filter.search
      })

      return await SUserReservation.get(filters).then(
        ({ data }) => {
          this.tablePending.totalRows = data.total_rows
          return data.items
        }
      ).catch(() => {
        this.tablePending.totalRows = 0
        return []
      })
    },

    async getOptions () {
      await SSharedList.getCompanies().then(
        ({ data }) => {
          this.list.companies = data.map(
            company => ({
              text: company.name,
              value: company.id
            })
          )
        }
      ).catch(() => {
        this.list.companies = []
      })

      await SSharedList.getOrganizations().then(
        ({ data }) => {
          this.list.organizations = data
        }
      ).catch(() => {
        this.list.organizations = []
      })

      await SSharedList.getVehicleTypes().then(
        ({ data }) => {
          this.list.vehicleTypes = data.map(
            vehicleType => ({
              text: vehicleType.name,
              value: vehicleType.id
            })
          )
        }
      ).catch(() => {
        this.list.vehicleTypes = []
      })
    },

    onCreate () {
      this.reservation.id = 0
      this.reservation.company = null
      this.reservation.organization = null
      this.reservation.reservation_type = 'Service Driver'
      this.reservation.departure_date = this.dateFormatter(Date.now())
      this.reservation.departure_time = ''
      this.reservation.arrival_date = this.dateFormatter(Date.now())
      this.reservation.arrival_time = ''
      this.reservation.vehicle_type = null
      this.reservation.passenger_count = 0
      this.reservation.purpose = ''
      this.reservation.file_path = ''
      this.reservation.file_list = []
      this.list.files = []
      this.state.file = {
        having: false,
        removing: false,
        uploading: false,
        previewing: false
      }
      this.state.allowSubmit = 0

      this.$bvModal.show('modal-reservation-form')
    },

    onView (item) {
      this.state.viewing = true

      this.reservation.id = item.id
      this.reservation.created_at = this.dateTimeFormatter(item.created_at)
      this.reservation.status = item.status
      this.reservation.user_name = item.user_name
      this.reservation.company_name = item.company_name
      this.reservation.organization_name = item.organization_name
      this.reservation.reservation_type = item.reservation_type
      this.reservation.departure_date = item.departure_date
      this.reservation.departure_time = this.timeFormatterTwelveHours(`${item.departure_date} ${item.departure_time}`)
      this.reservation.arrival_date = item.arrival_date
      this.reservation.arrival_time = this.timeFormatterTwelveHours(`${item.arrival_date} ${item.arrival_time}`)
      this.reservation.vehicle_type_name = item.vehicle_type_name
      this.reservation.passenger_count = item.passenger_count
      this.reservation.purpose = item.purpose
      this.reservation.attachments = item.attachments
      this.reservation.file_path = item.file_path

      this.$bvModal.show('modal-view-form')
    },

    onCancel (item) {
      this.cancellation.id = item.id
      this.$bvModal.show('modal-cancel-form')
    },

    onCompanyChange () {
      this.reservation.organization = null
    },

    onReservationTypeChange (reservationType) {
      if (reservationType === 'Service Driver') {
        this.reservation.vehicle_type = null
        this.reservation.passenger_count = 0
      }
    },

    async onCancelReservation (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.formCancelReservation.validate().then(async success => {
        if (success) {
          this.$swal.fire({
            icon: 'question',
            title: 'Confirmation',
            text: 'Mark Reservation as Cancelled?',
            confirmButtonColor: '#FF2929',
            confirmButtonText: 'Mark as Cancelled',
            cancelButtonColor: '#737373',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              return this.onPutCancel()
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        } else {
          this.$bvModal.msgBoxOk('Oops! Your inputs seem to be incorrect or incomplete, please check and try again.', {
            title: 'Validation Error',
            size: 'sm',
            buttonSize: 'md',
            okVariant: 'danger',
            centered: true
          })
        }
      })
    },

    async onPutCancel () {
      this.state.busy = true
      return new Promise(resolve => {
        SUserReservation.cancel(this.cancellation).then(
          ({ data }) => {
            this.$bvModal.hide('modal-cancel-form')
            this.$swal.fire({
              icon: 'success',
              title: 'Successful',
              text: data.message,
              confirmButtonColor: '#06C270',
              confirmButtonText: 'Dismiss'
            }).then(() => {
              this.$refs.table.refresh()
            })
          }
        ).catch(
          error => {
            if (error instanceof AxiosError) {
              if (error.code === 422) {
                this.$refs.form.setErrors(error.message)
              }
            }
          }
        ).finally(resolve)
      }).then(() => {
        this.state.busy = false
      })
    },

    async onValidate (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.form.validate().then(async success => {
        if (success) {
          this.$swal.fire({
            icon: 'question',
            title: 'Confirmation',
            text: 'Submit this Reservation?',
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Submit Reservation',
            cancelButtonColor: '#737373',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              return this.onPost()
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        } else {
          this.$bvModal.msgBoxOk('Oops! Your inputs seem to be incorrect or incomplete, please check and try again.', {
            title: 'Validation Error',
            size: 'sm',
            buttonSize: 'md',
            okVariant: 'danger',
            centered: true
          })
        }
      })
    },

    async onPost () {
      this.state.busy = true
      return new Promise(
        resolve => {
          SUserReservation.post(this.reservation).then(
            ({ data }) => {
              this.$bvModal.hide('modal-reservation-form')
              this.$swal.fire({
                icon: 'success',
                title: 'Successful',
                text: data.message,
                confirmButtonColor: '#06C270',
                confirmButtonText: 'Dismiss'
              }).then(() => {
                this.$refs.table.refresh()
              })
            }
          ).catch(
            error => {
              if (error instanceof AxiosError) {
                if (error.code === 422) {
                  this.$refs.form.setErrors(error.message)
                }
              }
            }
          ).finally(resolve)
        }
      ).then(() => {
        this.state.busy = false
      })
    },

    async onUploadAttachment () {
      this.state.file.uploading = true

      const formBody = new FormData()

      let counter = 0

      this.list.files.forEach(file => {
        formBody.append(`file[${counter}]`, file)
        counter++
      })

      formBody.append('file_path', this.reservation.file_path)

      await SUserAttachment.upload(formBody).then(
        response => {
          this.reservation.file_path = response.data.file_path
          this.reservation.file_list.push(...response.data.file_list)
          this.list.files = []
          this.state.file.uploading = false
        }
      ).catch(
        () => {
          this.list.files = []
          this.state.file.uploading = false
        }
      )
    },

    async onRemoveFile (file) {
      this.state.file.removing = true

      await SUserAttachment.removeFile(this.reservation.file_path, file.file_hash_name).then(
        () => {
          this.reservation.file_list = _.reject(this.reservation.file_list, currentObject => {
            return currentObject.file_hash_name === file.file_hash_name
          })
          this.state.file.removing = false
        }
      ).catch(
        () => {
          this.state.file.removing = false
        }
      )
    },

    async onRemoveAllFile () {
      this.state.file.removing = true

      await SUserAttachment.removeAllFile(this.reservation.file_path).then(
        () => {
          this.reservation.file_path = ''
          this.reservation.file_list = []
          this.state.file.removing = false
        }
      ).catch(
        () => {
          this.list.files = []
          this.state.file.removing = false
        }
      )
    },

    async onPreviewAttachment (item) {
      if (this.state.viewing) {
        this.file.name = item.file_original_name
        this.file.preview = this.getImageUrl(`${this.reservation.file_path}/${item.file_hash_name}`)
        this.$bvModal.show('modal-preview-image')
      } else {
        this.file.name = item.file_original_name
        this.file.preview = this.getImageUrl(`uploads/${this.reservation.file_path}/${item.file_hash_name}`)
        this.$bvModal.show('modal-preview-image')
      }
    }
  }
}
</script>
